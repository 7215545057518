.otp-input {
    padding: 10px;
    border-radius: 0;
    font-size: 32px;
    margin: 0 !important;
}

.opt-input-container {
    display: flex;
    justify-content: space-between;
}

.reset-otp-container {
    display: inline !important;
}