.main-form {
  border-radius: 10px;
  box-shadow: 2px 0px 5px #80808085;
  padding: 10px;
}

.picker>div>div:nth-child(2) {
  flex-direction: row-reverse !important;
  gap: 0.4rem;
}

.picker>div>div:nth-child(2)>div>div>div>div {
  border-radius: 1px !important;
  border: 0;
  width: 4px !important;
  height: 8px !important;
  margin: 2px !important;
  top: 1px;
}

.picker>div>div:nth-child(2)>div:nth-child(2)>div {
  height: 10px !important;
  border-radius: 0 !important;
  border-radius: 1px !important;
}

.picker>div>div:nth-child(2)>div:nth-child(2)>div>div {
  height: 10px !important;
  border-radius: 1px !important;
}

.ranges .css-jsexje-MuiSwitch-thumb {
  border-radius: 0 !important;
  height: 42px !important;
  top: 0 !important;
}

.ranges .css-1ls7trv-MuiSwitch-root {
  position: relative;
  width: 49px !important;
  height: 42px !important;
  border-radius: 0 !important;
  /* z-index: 99999 !important; */
}

.ranges .css-1i8lvs-MuiButtonBase-root-MuiSwitch-switchBase {
  padding: 0 !important;
  width: 49px !important;
  height: 42px !important;
  border-radius: 0 !important;
}

.ranges .PrivateSwitchBase-input {
  z-index: 99999 !important;
}

.css-f91u4h-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}


.model ::-webkit-scrollbar,
.custom-scroll ::-webkit-scrollbar,
.rejection-input ::-webkit-scrollbar,
.chat-box-container ::-webkit-scrollbar,
.chat-list-container ::-webkit-scrollbar {
  width: 12px;
  height: 20% !important;
  margin-right: 20px !important;
  left: 30px !important;
  right: 100px !important;
  margin: 20px !important;
}
.rejection-input{
  border:0.5px solid rgba(128, 128, 128, 0.322)!important;
  border-radius: 5px !important;
}

.model ::-webkit-scrollbar-track,
.custom-scroll ::-webkit-scrollbar-track,
.rejection-input ::-webkit-scrollbar-track,
.chat-box-container ::-webkit-scrollbar-track,
.chat-list-container ::-webkit-scrollbar-track {
  height: 90% !important;
  background-color: #F0F0F0 !important;
  border-radius: 50px !important;
}

.model ::-webkit-scrollbar-thumb,
.custom-scroll ::-webkit-scrollbar-thumb,
.rejection-input ::-webkit-scrollbar-thumb,
.chat-box-container ::-webkit-scrollbar-thumb,
.chat-list-container ::-webkit-scrollbar-thumb {
  background-color: #E4E4E4 !important;
  border-radius: 50px !important;
}

.custom-scroll ::-webkit-scrollbar-track {
  border-radius: 0px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}


.chat-list-header {
  margin: auto;
  display: block;
  /* padding: 15px 0; */
  background-color: #212934 !important;
}

.chat-search-input {
  display: block;
  margin: auto;
}

.chat-item {
  display: block;
  margin: auto;
  padding: 20px 0;
  cursor: pointer;
}

/* .chat-list-body {
  height: 700px !important;
  overflow-y: auto;
} */

.chat-box-header {
  display: block;
  margin: auto;
}

.chat-box-body {
  display: block;
  margin: auto;
  padding: 0 20px 0;
  /* height: 600px !important; */
  overflow-y: auto;
  height: 580px;
  width: 100%;
}

.received {
  background: #F3FAFA;
  max-width: 75%;
  width: auto;
  min-width: 20px;
  color: #212934;
  font-size: 14px;
  border-radius: 20px;
  border-bottom-left-radius: 0 !important;
  padding: 10px;
}

.received p,
.sent p {
  font-size: 16px !important;
}

.sent {
  background: #3FABAE !important;
  max-width: 75%;
  width: auto;
  min-width: 20px;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  border-bottom-right-radius: 0 !important;
  padding: 10px;
}

.pdf {
  padding: 5px !important;
  background-color: white !important;
  border: 1.5px solid #3FABAE;
}

.pdf a,
.pdf p {
  color: #252A34 !important;
  font-weight: 600;
  font-size: 14px;
  padding: 0 3px;
}

.MuiInputLabel-shrink {
  display: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 10px;
}