.border-r-10px fieldset{
    border-radius: 10px !important;
}
.basic-date-picker .MuiFormControl-root{
    width: 100%;
}
.basic-date-picker svg{
    color: var(--primary-color);
}
.red-border{
    outline: 1.5px solid #d32f2f !important;
    border-radius: 10px !important;
}

.red-border > label{
    color: #d32f2f !important;    
}