.main-table table th:nth-child(1) {
  background-color: white;
  position: sticky;
  left: 0;
}
.main-table table th:nth-child(2) {
  background-color: white;
  position: sticky;
  left: 50px;
}
.main-table table tbody td:nth-child(1) {
  left: 0;
  background-color: white;
  z-index: 1;
  position: sticky;
}
.main-table table tbody td:nth-child(2) {
  background-color: white;
  position: sticky;
  z-index: 1;
  left: 50px;
}
