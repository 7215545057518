.code_select {
    width: 110px;
    text-align: center !important;
    padding: 0 !important;
    margin-inline-end: 6px !important;
}

.select fieldset {
    border-radius: 0 !important;
    border-color: #979797 !important;
}

.phone-select>svg {
    display: none;
}

.input_class {
    width: 100% !important;
    min-height: 56px;
    background-color: #FBFBFB !important;
    border: 0 !important;

}

.button_class {
    background-color: #FBFBFB !important;
    border: 0 !important;
    border-right: 0.5px solid rgba(128, 128, 128, 0.315) !important;
}

.dropdownStyle {
    overflow-y: auto !important;
}