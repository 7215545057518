.file-input{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.add-activity-img{
   
    height: 140px;
    border-radius: 10px;
    background-color: #f3f3f3;
    text-align: center;
    color: var(--primary-color);
    cursor: pointer;
    padding: 5px;
}

.add-activity-img img{
    display: block;
    margin: auto;
    padding-top: 40px;
}
.upload-input{
    border-radius: var(--lg-radius) !important;
    border: solid 1px #bebebe !important;
    color: #bebebe !important;
    position: relative;
    padding: 14px;
}
.upload-input img{
    position: absolute;
    right: 0;
    top: 5px;
}
.error-border{
    border: 1px solid var(--formik-error) !important;
}